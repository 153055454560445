import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  RightColumn,
  Image,
} from "../../globalStyles";
import cheesesMarket1 from "../../assets/images/cheeses-market/cheeses-market-1.jpg";
import cheesesMarket2 from "../../assets/images/cheeses-market/cheeses-market-2.jpg";
import cheesesMarket3 from "../../assets/images/cheeses-market/cheeses-market-3.jpg";

const CheesesMarket = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Marché des fromages</span>
              <br />
              <span>d'alpage</span>
            </h2>
            <Paragraph>
              <p>
                Le marché des fromages a lieu chaque année le premier weekend de
                novembre au cœur du village de Bruson dans une ambiance
                familiale et conviviale. L’événement mêle subtilement
                dégustation et vente directe de fromages d’alpage avec près de
                10 fromages différents à découvrir chaque année, concerts,
                animations et ateliers pour les enfants.
              </p>
            </Paragraph>
            <Paragraph>
              <h4>Prochaine édition</h4>
              <p style={{ fontStyle: "italic" }}>2 novembre 2025</p>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={cheesesMarket1} alt="Marché des fromages d'alpage" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <Image>
              <img src={cheesesMarket3} alt="Marché des fromages d'alpage" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <Image>
              <img src={cheesesMarket2} alt="Marché des fromages d'alpage" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default CheesesMarket;
