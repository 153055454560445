import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  RightColumn,
  Image,
  List,
} from "../../globalStyles";
import market1 from "../../assets/images/market/market-1.jpg";
import market2 from "../../assets/images/market/market-2.jpg";
import market3 from "../../assets/images/market/market-3.jpg";

const Market = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Grand Marché</span>
              <br />
              <span>des Terroirs Alpins</span>
            </h2>
            <Paragraph>
              <p>
                Véritable ode aux Alpes et au patrimoine alpin, la première
                édition du Grand Marché des Terroirs Alpins a proposé aux
                visiteurs une immersion le temps d’un weekend dans la richesse
                et la diversité des traditions, pratiques et spécialités des
                régions de montagne.
              </p>
              <p>
                De la Slovénie à la France, en passant par l’Italie, l’Autriche
                et la Suisse, près de 70 producteurs ont fait découvrir leur
                savoir-faire, produits et mets emblématiques de leurs régions,
                avec à la clé de nombreuses activités de médiation et de
                sensibilisation pour les familles et plusieurs ateliers de
                dégustation proposés par la Slow Mobile.
              </p>
              <p>
                Un repas concocté par le Chef Alex Deprez avec les produits du
                marché a su ravir les papilles des plus fins gastronomes.
              </p>
              <p>
                L’événement a également favorisé les rencontres et les
                discussions sur les enjeux des régions de montagne avec
                plusieurs tables rondes. Parmi ces rencontres, on peut citer les
                conférences animées par Stéphane Benoit-Godet, ancien rédacteur
                en Chef du Temps sur le terroir et la culture, les masterclass
                données par José Vouillamoz autour des cépages des alpes ou
                encore la conférence sur l’Amigne, avec notamment la
                participation du prix Nobel d’astrophysique Michel Mayor et de
                la journaliste Manuela Maury.
              </p>
            </Paragraph>
            <Paragraph>
              <h4>Le Grand Marché 2024 en quelques chiffres</h4>
              <List>
                <li>6 500 visiteurs</li>
                <li>70 producteurs</li>
                <li>
                  9 pôles: conférences, producteurs et terroir, viticulture,
                  littérature, animations, ateliers, gastronomie, bières et
                  concerts
                </li>
                <li>19 concerts</li>
                <li>35 artistes</li>
                <li>6 conférences</li>
                <li>19 ateliers / dégustations</li>
                <li>4 contes</li>
              </List>
            </Paragraph>
            <Paragraph>
              <h4>Prochaine édition</h4>
              <p style={{ fontStyle: "italic" }}>14 – 15 Juin 2025</p>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={market1} alt="Le grand marché des terroirs alpins" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <Image>
              <img src={market2} alt="Le grand marché des terroirs alpins" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <Image>
              <img src={market3} alt="Le grand marché des terroirs alpins" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default Market;
