import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  RightColumn,
  Image,
  List,
} from "../../globalStyles";
import { VideoWrapper } from "./RouliBouli.styled";
import rouliBouli1 from "../../assets/images/rouli-bouli/rouli-bouli-1.jpg";
import rouliBouli2 from "../../assets/images/rouli-bouli/rouli-bouli-2.jpg";
import rouliBouli3 from "../../assets/images/rouli-bouli/rouli-bouli-3.jpg";
import rouliBouli4 from "../../assets/images/rouli-bouli/rouli-bouli-4.jpg";
import rouliBouli5 from "../../assets/images/rouli-bouli/rouli-bouli-5.jpg";

const RouliBouli = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Rouli Bouli</span>
            </h2>
            <Paragraph>
              <p>
                Ce nouveau parcours de balles inédit ludique et interactif a été
                une véritable réussite attirant près de 20 000 rouliboulistes de
                juin à septembre 2024. Ode au bois local avec un quizz sur les
                essences de bois du val de Bagnes à retrouver le long du
                parcours, les visiteurs étaient invités, munis de leur balle
                réalisée en mélèze, à emprunter la route forestière reliant
                Bruson au Châble, le long de laquelle 15 constructions et
                mécanismes étaient à découvrir.
              </p>
            </Paragraph>
            <Paragraph>
              <h4>Les installations</h4>
              <p>
                Une dizaine d’installations seront créées par différents
                intervenants, artisans locaux, artistes régionaux...
              </p>
              <p>
                Un demi-kilomètre de chéneaux, longe le chemin et nous conduit à
                chaque oeuvre. Les chéneaux sont fabriqués par le triage
                forestier Combins-Catogne. Leurs longueurs et formes, leurs
                essences ainsi que leurs types de fabrication sont diverses et
                variées.
              </p>
            </Paragraph>
            <Paragraph>
              <h4>Le chemin pédestre</h4>
              <List>
                <li>2,4 km / 42 minutes à pied</li>
                <li>181 m ↓ / 0 m ↑</li>
                <li>
                  Depuis le restaurant Le Carrefour du Village > Chemin du
                  Seudzay > Chemin des Bamettes > Route de Clouchèvre > pour
                  finir au pont du Torrent de Bruson
                </li>
              </List>
            </Paragraph>
            <Paragraph>
              <h4>Prochaine édition</h4>
              <p style={{ fontStyle: "italic" }}>Dès juin 2025</p>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={rouliBouli1} alt="Rouli Bouli" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <Image>
              <img src={rouliBouli2} alt="Rouli Bouli" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <Image>
              <img src={rouliBouli3} alt="Rouli Bouli" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <Image>
              <img src={rouliBouli4} alt="Rouli Bouli" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <Image>
              <img src={rouliBouli5} alt="Rouli Bouli" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <VideoWrapper>
              <iframe
                src="https://www.youtube.com/embed/Wk1W3XPnIQU"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube video player"
                style={{ width: "100%", height: "100%" }}
                frameBorder="0"
              ></iframe>
            </VideoWrapper>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default RouliBouli;
