import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  List,
  RightColumn,
  Image,
} from "../../globalStyles";
import dansLesReves from "../../assets/images/projects/dans-les-reves.jpg";
import bruissons from "../../assets/images/projects/bruissons.jpg";
import marcheFromages from "../../assets/images/projects/marche-fromages.jpg";
import batinta from "../../assets/images/projects/batinta.jpg";

const Projects = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Projets</span>
            </h2>
            <h4>
              Développer les traditions et les savoir-faire bagnards, proposer
              de nouveaux produits touristiques en collaborant avec les
              entreprises de la région.
            </h4>
            <Paragraph>
              <p>
                {" "}
                Le PALP souhaite remettre au goût du jour certaines coutumes
                locales, en travaillant main dans la main avec les artisans et
                les villageois, afin de relancer un pan de l’économie alpine. Il
                entend mettre en place des projets participatifs, qui impliquent
                la population et dont les bénéfices sont réinvestis dans les
                commerces, restaurants et entreprises locales. Le but est
                d’imaginer de nouvelles manières de promouvoir l’agriculture
                valaisanne et ses produits, en proposant des projets locaux qui
                soient exportables dans différents lieux du canton mais aussi à
                l’étranger afin de représenter la Suisse à l’échelle
                internationale.
              </p>
            </Paragraph>
            <Paragraph>
              <h4>Projets réalisés en 2019</h4>
              <List>
                <li>Implantation des bureaux du PALP à Bruson</li>
                <li>Lancement du projet PALP Village</li>
                <li>Exposition photographique autour de la raclette</li>
                <li>
                  Publication du livre «Le Raclette», coédition avec le Musée de
                  Bagnes
                </li>
                <li>
                  Projet participatif «Bruson capitale de la rencontre» avec
                  spectacle dans tout le village, compagnie Baur
                </li>
                <li>
                  Création d’une scénographie d’une demi-meule DJ Raclette AOP
                  en char, présentée à la Fête des vignerons à Vevey, à Swiss
                  life talent à Berne et à la Fête fédérale de la musique
                  populaire à Montana
                </li>
              </List>
            </Paragraph>
            <Paragraph>
              <h4>Projets réalisés en 2020</h4>
              <List>
                <li>Projet et exposition «Bruisson»</li>
                <li>
                  Création musicale participative «Écoute-voir» réalisée par
                  Vouipe, artiste valaisan de Bovernier
                </li>
                <li>BIS - Balade gourmande et musicale</li>
                <li>
                  Le Petit resto culturel, covid compatible dans le village de
                  Bruson
                </li>
                <li>
                  Four à raclette musical «Raclophonic», projet qui a servi à
                  des artistes
                </li>
                <li>
                  Collaboration avec le Meg, Musée d’ethnologie pour brunch
                  musical et sélection de sonorités
                </li>
                <li>
                  Livre sonore «Kipick» pour les enfants avec la dessinatrice
                  Adrienne Barman
                </li>
              </List>
            </Paragraph>
            <Paragraph>
              <h4>Projets réalisés en 2021</h4>
              <List>
                <li>
                  Projet et exposition «Bruson dessine son rêve», 107
                  dessinateurs qui ont rencontré 107 familles
                </li>
                <li>
                  Constitution d’un jury interdisciplinaire pour la réalisation
                  d’un rêve: sensibilisation à des acteurs extérieurs sur les
                  envies et enjeux des villages de montagne
                </li>
                <li>Publication «Dans les rêves»</li>
                <li>Jukebox participatif villageois</li>
              </List>
            </Paragraph>
            <Paragraph>
              <h4>Projets réalisés en 2022</h4>
              <List>
                <li>Marché des fromages d'alpage</li>
                <li>Projet et exposition «Batinta»</li>
                <li>Projet et exposition «Les Secrets du val de Bagnes»</li>
                <li>Cabane à BD créées par les Frères Chapuisat</li>
                <li>
                  Bar Electroclette + arche, collaboration avec la Berner
                  Fachhochschule/Haute École spécialisée bernoise et la faculté
                  du Pérou Pontifica Universidad Católica del Perú-PUCP
                </li>
              </List>
            </Paragraph>
            <Paragraph>
              <h4>Projet réalisé en 2023</h4>
              <List>
                <li>Marché des fromages d'alpage</li>
                <li>Projet et exposition sur la mort</li>
              </List>
            </Paragraph>
            <Paragraph>
              <h4>Projet réalisé en 2024</h4>
              <List>
                <li>Rouli Bouli</li>
                <li>Le Grand Marché des Terroirs Alpins</li>
                <li>
                  Exposition autour de la raclette par Léa Dorsaz et Pierre
                  Daendliker
                </li>
                <li>Chroniques Palpiennes par Hélène Becquelin</li>
              </List>
            </Paragraph>
            <Paragraph>
              <h4>Projets à venir</h4>
              <List>
                <li>Maison de la raclette</li>
                <li>Rouli Bouli 2.0</li>
                <li>Le Grand Marché des Terroirs Alpins</li>
              </List>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={dansLesReves} alt="Exposition" />
              <figcaption>© Emilien Itim</figcaption>
            </Image>
            <Image>
              <img src={bruissons} alt="Exposition" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img src={marcheFromages} alt="Marché des fromages" />
              <figcaption>© Martine Jaques</figcaption>
            </Image>
            <Image>
              <img src={batinta} alt="Batinta" />
              <figcaption>© Cyril Perregeaux</figcaption>
            </Image>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default Projects;
