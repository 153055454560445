import styled from "styled-components";

export const OpeningHours = styled.div`
  div {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 640px) {
      &:not(:first-child):not(:last-child) {
        flex-direction: column;
      }
    }

    p {
      margin-bottom: 0rem !important;
    }

    span {
      width: 176px;

      @media screen and (max-width: 640px) {
      width: fit-content;
      margin-right: 0.5rem;
      }
    }
    }
  }
`;
