/* eslint-disable jsx-a11y/iframe-has-title */
import { useState, useRef, useEffect } from "react";
import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  RightColumn,
  Image,
} from "../../globalStyles";
import { Helmet } from "react-helmet";
import { OpeningHours } from "./Brusonette.styled";
import { Link } from "../../globalStyles";
import brusonette1 from "../../assets/images/brusonette/brusonette-1.jpg";
import brusonette2 from "../../assets/images/brusonette/brusonette-2.jpg";
import brusonette3 from "../../assets/images/brusonette/brusonette-3.jpg";
import brusonette4 from "../../assets/images/brusonette/brusonette-4.jpg";
import brusonette5 from "../../assets/images/brusonette/brusonette-5.jpg";

const Brusonette = () => {
  const refContainer = useRef(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  function noteImgSize() {
    const { offsetWidth, offsetHeight } = refContainer.current;
    setImageSize({ width: offsetWidth, height: offsetHeight });
  }

  useEffect(() => {
    const handleWindowResize = () => noteImgSize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Main>
      <Helmet>
        <title>Epicerie la Brusonette</title>
        <meta
          name="description"
          content="La Brusonette, c’est l’épicerie du village! Son souhait est de valoriser l'économie locale."
        />
        <meta
          property="og:image"
          content="https://www.palpvillage.ch/og/epicerie-la-brusonette.jpg"
        />
      </Helmet>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Epicerie la Brusonette</span>
            </h2>
            <h4>
              Son souhait est de valoriser l'économie locale, entre valorisation
              des produits du terroirs et réponse aux besoins des habitants.
            </h4>
            <Paragraph>
              <p>
                L’épicerie offre la possibilité de trouver toutes sortes de
                produits du terroir, frais et de première nécessité, ainsi que
                des cosmétiques, des jouets reconditionnés et des articles de la
                boutique du PALP Festival. Elle valorise les produits locaux et
                offre aussi un espace de rencontre.
              </p>
            </Paragraph>
            <Paragraph>
              <h4>Horaires d’ouverture</h4>
              <OpeningHours>
                <div>
                  <span>Lundi: </span>
                  <p>fermé</p>
                </div>
                <div>
                  <span>Mardi au vendredi: </span>
                  <p>8h30 – 12h00 / 14h00 – 18h30</p>
                </div>
                <div>
                  <span>Samedi: </span>
                  <p>8h30 – 12h30 / 13h30 – 17h00</p>
                </div>
                <div>
                  <span>Dimanche:</span>
                  <p>fermé</p>
                </div>
              </OpeningHours>
            </Paragraph>
            <Paragraph>
              <h4>Infos pratiques</h4>
              <p>
                Téléphone: <Link href="tel:+41275651619">+41 27 565 16 19</Link>
                <br />
                Adresse:{" "}
                <Link
                  href="https://maps.app.goo.gl/KpM6SjYC2b7Ggkib9"
                  target="_blank"
                  rel="noreferrer"
                >
                  Chemin de Crètadzera 35, 1934 Bruson
                </Link>
              </p>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={brusonette1} alt="La Brusonette" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img src={brusonette2} alt="La Brusonette" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img src={brusonette3} alt="La Brusonette" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img src={brusonette4} alt="La Brusonette" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img
                ref={refContainer}
                onLoad={noteImgSize}
                src={brusonette5}
                alt="La Brusonette"
              />
              <figcaption>© PALP Village</figcaption>
            </Image>

            <iframe
              width={imageSize.width}
              height={imageSize.height}
              frameborder="0"
              src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=La%20Brusonette,%20Chemin%20de%20Cr%C3%A8tadzera%2035,%201934%20Bruson+(La%20Brusonette)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default Brusonette;
