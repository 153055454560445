/* eslint-disable jsx-a11y/iframe-has-title */
import { useState, useRef, useEffect } from "react";
import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  RightColumn,
  Image,
} from "../../globalStyles";
import { Helmet } from "react-helmet";
import carrefour1 from "../../assets/images/carrefour/carrefour-1.jpg";
import carrefour2 from "../../assets/images/carrefour/carrefour-2.jpg";
import carrefour3 from "../../assets/images/carrefour/carrefour-3.jpg";
import { Link } from "../../globalStyles";
import { OpeningHours } from "./Carrefour.styled";

const Carrefour = () => {
  const refContainer = useRef(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  function noteImgSize() {
    const { offsetWidth, offsetHeight } = refContainer.current;
    setImageSize({ width: offsetWidth, height: offsetHeight });
  }

  useEffect(() => {
    const handleWindowResize = () => noteImgSize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Main>
      <Helmet>
        <title>Carrefour du Village</title>
        <meta
          name="description"
          content="Le Carrefour, c’est le lieu animé au coeur du village, ouvert à tous, où il fait bon partager échanger et déguster des produits du terroir."
        />
        <meta
          property="og:image"
          content="https://www.palpvillage.ch/og/carrefour-du-village.jpg"
        />
      </Helmet>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Restaurant Carrefour</span>
              <br />
              <span>du Village Bruson</span>
            </h2>
            <h4>Restaurant, café, résidences d'artistes et lieu de création</h4>
            <Paragraph>
              <p>
                Le Carrefour à Bruson, c’est le lieu animé au coeur du village,
                ouvert à tous. La cuisine est tournée exclusivement vers les
                produits frais, locaux et de saison, sous la spatule de Sabine
                Besse.
              </p>
              <p>
                C’est également un café et un lieu de création, de résidences
                d’artistes et chercheurs. On y organise des projections, des
                concerts, ainsi que des expositions, des ateliers et toutes
                sortes d’événements.
              </p>
              <p>
                Lieu propice aux bons repas, version recettes Grand-Maman, aux
                rencontres, aux discussions, à la lecture et à la création, on
                s’y sent comme à la maison!
              </p>
            </Paragraph>
            <Paragraph>
              <h4>Horaires</h4>
              <OpeningHours>
                <p>
                  <span>Lundi:</span>fermé
                </p>
                <p>
                  <span>Mardi:</span>10h00 – 16h00
                </p>
                <p>
                  <span>Mercredi:</span>10h00 – 20h00
                </p>
                <p>
                  <span>Jeudi:</span>10h00 – 22h00
                </p>
                <p>
                  <span>Vendredi et samedi:</span>10h00 – 23h00
                </p>
                <p>
                  <span>Dimanche:</span>fermé
                </p>
              </OpeningHours>
            </Paragraph>
            <Paragraph>
              <OpeningHours>
                <p>
                  <span>Horaires cuisine midi:</span>12h00 – 14h00
                </p>
                <p>
                  <span>Horaires cuisine soir:</span>18h30 – 20h30
                </p>
              </OpeningHours>
            </Paragraph>
            {/* <Paragraph>
              <p style={{ color: "#ff4d6d", fontStyle: "italic" }}>
                Le Carrefour sera fermé du 23 septembre au 7 octobre
              </p>
            </Paragraph> */}
            <Paragraph>
              <h4>Réservations</h4>
              <p>
                Téléphone:{" "}
                <Link href="tel:+410275655050">+41 27 565 50 50</Link>
                <br />
                Email:{" "}
                <Link href="mailto:carrefour@palpfestival.ch">
                  carrefour@palpfestival.ch
                </Link>
              </p>
            </Paragraph>
            {/* <Paragraph>
              <h4>Ateliers</h4>
              <Link href="/evenements-et-ateliers">Programme</Link>
            </Paragraph> */}
            <Paragraph>
              <h4>Adresse</h4>
              <Link
                href="https://maps.app.goo.gl/vUu448mo6cBGLFJC9"
                target="_blank"
                rel="noreferrer"
              >
                <p>Chemin de Crètadzera 17, 1934 Bruson</p>
              </Link>
            </Paragraph>
            <Paragraph>
              <h4>Carte et menu sur nos réseaux</h4>
              <div style={{ display: "flex", gap: "1rem", marginTop: "8px" }}>
                <Link
                  href="https://www.facebook.com/carrefour.du.village.bruson"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#ff4d6d",
                    borderRadius: "50%",
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-facebook"
                  >
                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                  </svg>
                </Link>
                <Link
                  href="https://www.instagram.com/palp_festival/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#ff4d6d",
                    borderRadius: "50%",
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-instagram"
                  >
                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                    <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
                  </svg>
                </Link>
              </div>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={carrefour1} alt="Le Carrefour" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <Image>
              <img src={carrefour2} alt="Le Carrefour" />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <Image>
              <img
                ref={refContainer}
                onLoad={noteImgSize}
                src={carrefour3}
                alt="Le Carrefour"
              />
              <figcaption>© Cyril Perregaux</figcaption>
            </Image>
            <iframe
              width={imageSize.width}
              height={imageSize.height}
              frameborder="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Restaurant%20Carrefour%20%20du%20village,%20Chemin%20de%20Cr%C3%A8tadzera%2017,%201934%20Bruson+(Restaurant%20Carrefour%20du%20village,)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default Carrefour;
