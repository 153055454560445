/* eslint-disable jsx-a11y/iframe-has-title */
import { useState, useRef, useEffect } from "react";
import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  List,
  RightColumn,
  Image,
} from "../../globalStyles";
import { Helmet } from "react-helmet";
import { Link } from "../../globalStyles";
import cabaneInterieur from "../../assets/images/cabane/interieur.jpg";
import freresChapuisat from "../../assets/images/cabane/freres-chapuisat.jpg";
import books from "../../assets/images/cabane/books.jpg";
import girl from "../../assets/images/cabane/girl.jpg";

const Cabane = () => {
  const refContainer = useRef(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  function noteImgSize() {
    const { offsetWidth, offsetHeight } = refContainer.current;
    setImageSize({ width: offsetWidth, height: offsetHeight });
  }

  useEffect(() => {
    const handleWindowResize = () => noteImgSize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Main>
      <Helmet>
        <title>Cabane à BD</title>
        <meta
          name="description"
          content="La Cabane à BD est un endroit étonnant où il fait bon de s’y installer pour y bouquiner."
        />
        <meta
          property="og:image"
          content="https://www.palpvillage.ch/og/cabane-a-bd.jpg"
        />
      </Helmet>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Cabane à BD</span>
            </h2>
            <h4>
              Bruson, un village propice aux expériences. La Cabane à BD est un
              endroit étonnant où il fait bon de s’installer pour bouquiner.
              Véritable labyrinthe de bois, elle a été construite dans une
              grange par les frères Chapuisat. Elle est mise à disposition de
              petits et grands, à la fois lieu de rencontre et espace ludique.
            </h4>
            <Paragraph>
              <p>
                Ouverte en tout temps, cette cabane à lecture appelle à la
                curiosité et permet de se réfugier en toute tranquillité.
                Construite par les frères Chapuisat, elle est un lieu atypique
                qui transforme l'espace de par son architecture. Inversant les
                liens extérieurs et intérieurs et jouant avec la perception
                d'une réalité subjective, c’est un environnement qui rompe les
                habitudes visuelles et intellectuelles, invitant à l’exploration
                de l’espace et de à la créativité.
              </p>
            </Paragraph>
            <Paragraph>
              <h4>Horaires d’ouverture</h4>
              <List>
                <li>
                  La cabane est ouverte de mars à novembre, du mardi au samedi,
                  aux heures d'ouverture du{" "}
                  <Link href="/carrefour-du-village">
                    Restaurant Carrefour du Village
                  </Link>
                  . Veuillez vous adresser au restaurant pour la visite.
                </li>
                {/* <li>
                  Fermeture dès la mi-automne et réouverture au printemps!
                  N’hésitez pas à appeler le restaurant si vous n’êtes pas sûr.
                </li> */}
              </List>
            </Paragraph>
            <Paragraph>
              <h4>Infos pratiques</h4>
              <List>
                <li>Les enfants doivent être accompagnés d'un adulte.</li>
                <li>
                  Ce projet n'aurait pas pu être réalisé sans la mise à
                  disposition de la grange de Monsieur et Madame Rast.
                </li>
              </List>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={cabaneInterieur} alt="Intérieur de la cabane à BD" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img src={books} alt="Livres" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img src={girl} alt="Enfant lisant une BD" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img
                ref={refContainer}
                onLoad={noteImgSize}
                src={freresChapuisat}
                alt="Les frères Chapuisat"
              />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <iframe
              width={imageSize.width}
              height={imageSize.height}
              frameborder="0"
              src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=Cabane%20%C3%A0%20BD,%20Chemin%20du%20Clou%2026,%201934%20Bruson+(Cabane%20%C3%A0%20BD)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default Cabane;
