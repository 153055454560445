import styled from "styled-components";

export const Program = styled.ul`
  display: flex;
  column-gap: 2rem;

  li {
    width: 50%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const OpeningHours = styled.div`
  p {
    display: flex;
    margin-bottom: 0rem !important;

    span {
      width: 200px;
    }
  }
`;
