import styled from "styled-components";

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Ratio 16:9 */
  margin-bottom: 1rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  figcaption {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #666;
  }
`;
